export default {
  122: "4' 0\"",
  124: "4' 1\"",
  127: "4' 2\"",
  130: "4' 3\"",
  132: "4' 4\"",
  135: "4' 5\"",
  137: "4' 6\"",
  140: "4' 7\"",
  142: "4' 8\"",
  145: "4' 9\"",
  147: "4' 10\"",
  150: "4' 11\"",
  152: "5' 0\"",
  155: "5' 1\"",
  157: "5' 2\"",
  160: "5' 3\"",
  163: "5' 4\"",
  165: "5' 5\"",
  168: "5' 6\"",
  170: "5' 7\"",
  173: "5' 8\"",
  175: "5' 9\"",
  178: "5' 10\"",
  180: "5' 11\"",
  183: "6' 0\"",
  185: "6' 1\"",
  188: "6' 2\"",
  190: "6' 3\"",
  193: "6' 4\"",
  196: "6' 5\"",
  198: "6' 6\"",
  201: "6' 7\"",
  203: "6' 8\"",
  206: "6' 9\"",
  208: "6' 10\"",
};
