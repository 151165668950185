<template>
  <div class="px-4">
    <nav class="border-b border-gray-200 mb-8">
      <div class="max-w-xl py-4 mx-auto font-medium">
        Miscarriage Odds
      </div>
    </nav>
    <div class="w-full max-w-xl mx-auto">
      <div class="flex justify-start mx-auto space-x-2">
        <div>
          <label for="age" class="block mo-label">Age</label>
          <div class="mt-1 w-24">
            <select id="age" v-model="inputs.age" class="w-full mo-select">
              <option></option>
              <option v-for="count in 40" :key="count" :value="55 - count + 1">{{ 55 - count + 1 }}</option>
            </select>
          </div>
        </div>
        <div>
          <label for="height" class="block mo-label">Height</label>
          <div class="mt-1 w-24">
            <select id="height" v-model="inputs.height" class="w-full mo-select">
              <option></option>
              <option v-for="(feet, cm) in heightOptions" :key="cm" :value="cm">{{ feet }}</option>
            </select>
          </div>
        </div>
        <div>
          <label for="weight" class="block mo-label">Weight</label>
          <div class="mt-1 w-32">
            <select id="weight" v-model="inputs.weight.pounds" class="w-full mo-select">
              <option></option>
              <option v-for="weight in weightOptions.lb" :key="weight" :value="weight">{{ weight }} lbs.</option>
            </select>
          </div>
        </div>
      </div>
      <table class="mx-auto w-full mt-8">
        <thead>
          <th></th>
          <th class="mo-label border-b border-gray-600 text-right p-2"><span class="hidden sm:inline-block">Day</span> 1</th>
          <th class="mo-label border-b border-gray-600 text-right p-2"><span class="hidden sm:inline-block">Day</span> 2</th>
          <th class="mo-label border-b border-gray-600 text-right p-2"><span class="hidden sm:inline-block">Day</span> 3</th>
          <th class="mo-label border-b border-gray-600 text-right p-2"><span class="hidden sm:inline-block">Day</span> 4</th>
          <th class="mo-label border-b border-gray-600 text-right p-2"><span class="hidden sm:inline-block">Day</span> 5</th>
          <th class="mo-label border-b border-gray-600 text-right p-2"><span class="hidden sm:inline-block">Day</span> 6</th>
          <th class="mo-label border-b border-gray-600 text-right p-2"><span class="hidden sm:inline-block">Day</span> 7</th>
        </thead>
        <tbody>
          <tr v-for="(week, i) in chunkedProbability" :key="i">
            <td class="mo-label border-r border-gray-600 pr-2 text-xs whitespace-nowrap">Wk {{ 2 + i }}</td>
            <td v-for="(prob) in week" :key="prob.day" class="font-mono text-xs sm:text-base text-right px-2 py-1 border-r border-b border-gray-200 hover:bg-gray-200">
              {{ probability(prob.day) }}
            </td>
          </tr>
        </tbody>
      </table>
      <p class="mt-16 text-sm font-medium text-gray-800">What is the science behind these odds?</p>
      <p class="text-sm text-gray-500">We're using the peer-reviewed data cited in <a href="https://datayze.com/miscarriage-chart" class="underline text-blue-600">datayze.com</a>.</p>
    </div>

  </div>
</template>

<script>
import chunk from 'lodash/chunk';
import filter from 'lodash/filter';
import map from 'lodash/map';
import range from 'lodash/range';

import heightOptions from './heightOptions';
import probabilityByDay from './probabilityByDay';

export default {
  name: 'Miscarriage Odds',

  data() {
    return {
      heightOptions,
      loaded: false,
      weightOptions: {
        kg: range(45, 110).reverse(),
        lb: range(100, 240).reverse(),
      },
      inputs: {
        age: 32,
        height: 163,
        previousBirths: 0,
        previousMiscarriages: 0,
        weight: {
          kilograms: '',
          pounds: 165,
        },
      },
      probabilityByDay,
      unit: {
        height: 'cm',
        weight: 'lb',
      },
    };
  },

  created() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params.height) {
      this.inputs.height = params.height * 100;
    }

    if (params.age) {
      this.inputs.age = params.age;
    }

    if (params.weight) {
      this.inputs.weight.kilograms = params.weight;
      this.inputs.weight.pounds = Math.round(params.weight * 2.20462);
    }

    this.loaded = true;
  },

  watch: {
    inputs: {
      handler(inputs) {
        if (!this.loaded) return;

        const urlParams = new URLSearchParams(window.location.search);
        console.log(this.weightInKilograms);
        urlParams.set('age', inputs.age);
        urlParams.set('weight', this.weightInKilograms);
        urlParams.set('height', this.heightInMetres);

        window.location.search = urlParams;
      },

      deep: true,
    },
  },

  computed: {
    mappedProbability() {
      return map(this.probabilityByDay, (probability, day) => ({ day, probability }));
    },

    chunkedProbability() {
      return filter(chunk(this.mappedProbability, 7), (chunk, i) => i < 18);
    },

    weightInKilograms() {
      return this.inputs.weight.pounds / 2.2046226218;
    },

    heightInMetres() {
      return this.inputs.height / 100;
    },

    ageMultiplier() {
      if (this.inputs.age < 35) {
        return 0.94;

      } else if (this.inputs.age < 40) {
        return 1.3;
      }

      return 2.6;
    },

    previousBirthsMultiplier() {
      if (!this.inputs.previousBirths) {
        return 1.03;
      }

      return 0.71;
    },

    previousMiscarriagesMultiplier() {
      if (!this.inputs.previousMiscarriages || this.inputs.previousMiscarriages < 3) {
        return 0.98;
      }

      return 1.89;
    },

    bodyMassIndex() {
      if (!this.weightInKilograms || !this.heightInMetres) return;

      return this.weightInKilograms / (this.heightInMetres * this.heightInMetres);
    },

    bodyMassIndexMultiplier() {
      if (!this.bodyMassIndex) return;

      if (this.bodyMassIndex < 25) {
       return 0.86;
      } else if (this.bodyMassIndex < 30) {
       return 1.12;
      } else if (this.bodyMassIndex < 35) {
       return 1.48;
      }

      return 1.89;
    },
  },

  methods: {
    probability(day) {
      var raw = this.probabilityByDay[day];
      var factors = [];

      if (this.inputs.age) {
        factors.push(this.ageMultiplier);
      }

      factors.push(this.previousBirthsMultiplier);
      factors.push(this.previousMiscarriagesMultiplier);
      factors.push(this.bodyMassIndexMultiplier);

      let factor = 1;

      if (factors.length > 0) {
        factor = factors.reduce(function (a, b) {
          return a + b;
        }) / factors.length;
      }
      return (raw * factor * 100).toFixed(1);
    },

    displayDay(day) {
      const week = Math.floor(day / 7);

      return `${week}.${day - week * 7 + 1}`;
    },
  },
};
</script>
